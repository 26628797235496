<template>
  <div
    :class="'agroup-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="header">
      <div class="base-info">
        <van-image
          round
          width="20vw"
          height="20vw"
          :src="agentInfo.AgentFace ? agentInfo.AgentFace : defaultFace"
          fit="cover"
        />
        <div class="info">
          <span class="name">{{ agentInfo.AgentName }}</span>
          <span class="item">ID: {{ agentInfo.AgentCode }}</span>
          <span class="item" @click="onCopy(agentInfo.InviteCode)"
            >{{ PAGELANG.邀请码 }}: {{ agentInfo.InviteCode }}</span
          >
        </div>
      </div>
    </div>
    <div
      style="padding-top: calc(120px + var(--aog-credit-menu-bottom-margin))"
    >
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :pulling-text="SYSLANG.下拉即可刷新"
        :loosing-text="SYSLANG.释放即可刷新"
        :loading-text="SYSLANG.loading"
      >
        <van-list
          v-model:loading="isloading"
          :finished="finished"
          :finished-text="SYSLANG.finished"
          :loading-text="SYSLANG.loading"
          @load="getAgentList"
          :offset="50"
        >
          <ul class="contacts">
            <template v-for="(citem, index) in contacts" :key="index">
              <li>
                <h2>
                  {{ citem.AgentName
                  }}<span class="status"
                    >{{ PAGELANG.下级 }}{{ citem.SubAgentCount }}
                  </span>
                </h2>
                <div class="contact">
                  <span class="contactinfo"
                    ><label class="title">{{ PAGELANG.直接上级 }}</label
                    ><span class="content">{{ citem.PAgentName }}</span></span
                  >
                  <span class="contactinfo"
                    ><label class="title">{{ PAGELANG.商号 }}</label
                    ><span class="content">{{ citem.AgentCode }}</span></span
                  >
                  <span class="contactinfo"
                    ><label class="title">{{ PAGELANG.电话 }}</label
                    ><span class="content">{{ citem.Mobile }}</span></span
                  >
                  <span class="contactinfo"
                    ><label class="title">{{ PAGELANG.Email }}</label
                    ><span class="content">{{ citem.Email }}</span></span
                  >
                </div>
                <div class="time">
                  {{ PAGELANG.注册时间 }}
                  <span>{{ formatDate(citem.AddDTime) }}</span>
                </div>
              </li>
            </template>
          </ul>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>

  <div class="pc-my-page">
    <MyMenu :agentInfo="agentInfo" pagename="agentgroup" />
    <div class="main">
      <div class="big-title">
        {{
          alevel == 1
            ? PAGELANG.一级代理
            : alevel == 2
            ? PAGELANG.二级代理
            : alevel == 3
            ? PAGELANG.三级代理
            : ""
        }}
      </div>
      <el-table
        :data="contacts"
        class="logs"
        size="large"
        :empty-text="SYSLANG.finished"
      >
        <el-table-column prop="AgentName" :label="REGLANG.agentname" />
        <el-table-column prop="AgentCode" :label="PAGELANG.商号" width="100" />
        <el-table-column prop="Mobile" :label="PAGELANG.电话" />
        <el-table-column prop="Email" :label="PAGELANG.Email">
          <template #default="scope">
            {{ scope.row.Email ? scope.row.Email : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="PAgentName"
          :label="PAGELANG.直接上级"
          width="140"
        />
        <el-table-column
          prop="SubAgentCount"
          :label="PAGELANG.下级数量"
          width="120"
        />
        <el-table-column :label="PAGELANG.注册时间" width="120" align="right">
          <template #default="scope">
            {{ formatDate(scope.row.AddDTime) }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="pagecount > 1"
        @current-change="changePage"
        layout="prev, pager, next"
        :page-size="pagesize"
        :pager-count="pagecount"
        :total="total"
      />
    </div>
  </div>
</template>
  
<script>
import { getCookie, formatDate } from "../util";
import { showLoadingToast, showToast, closeToast } from "vant";
import MyMenu from "../components/MyMenu.vue";

export default {
  components: {
    MyMenu,
  },
  data() {
    return {
      alevel: 0,
      agentInfo: [],
      defaultFace: require("@/assets/images/default_face.png"),
      refreshing: false,
      isloading: false,
      finished: false,
      contacts: [],
      pagesize: 10,
      curpage: 0,
      total: 0,
      pagecount: 0,
      init: true,
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  watch: {
    "$route.query"(val) {
      if (val && val.level) {
        this.alevel = val.level;
        this.refreshAgentList();
      }
    },
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    if (this.$route.query && this.$route.query.level)
      this.alevel = this.$route.query.level;

    this.PAGELANG = this.LANG.pages.agentgroup;
    this.REGLANG = this.LANG.pages.register;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.init = true;
      this.axios
        .get(this.actions.member)
        .then(({ data }) => {
          closeToast();
          let { code, msg, agentInfo } = data;
          if (code == "SUCCESS") {
            this.agentInfo = agentInfo;
            this.init = false;
            this.getAgentList();
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.GOODSLANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          console.log("views.AGroupList.getMemberInfo.error", error);
          closeToast();
          this.getMemberInfo();
        });
    },
    getAgentList() {
      if (this.init) {
        return false;
      }
      this.isloading = true;
      this.finished = true;

      console.log(this.alevel, this.pagesize, this.curpage);

      this.curpage++;

      this.axios
        .get(this.actions.agrouplist, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            alevel: this.alevel,
            pagesize: this.pagesize,
            curpage: this.curpage,
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.isloading = false;
          this.finished = data.isfinished ? true : false;
          this.refreshing = false;

          if (data.retcode == "OK") {
            this.curpage = data.curpage;
            this.pagesize = data.pagesize;
            this.pagecount = data.pagecount;
            this.total = data.total;
            //this.contacts.push(...data.contacts);

            this.contacts = this.contacts.concat(data.contacts);
          }
        })
        .catch((error) => {
          console.log("views.AGroupList.getAgentList.error", error);
          closeToast();
          this.curpage--;
          this.getAgentList();
        });
    },
    formatDate(d) {
      return formatDate(d);
    },
    onRefresh() {
      //this.getAgentList();
      //this.finished = false;
      this.getAgentList();
    },

    changePage(val) {
      this.curpage = val - 1;
      this.pagecount = 0;
      this.pagesize = 0;
      this.total = 0;
      this.contacts = [];
      this.getAgentList();
    },
  },
};
</script>

<style src="../assets/css/agentgroup.css" scoped>
</style>
<style scoped>
@media (max-width: 678px) {
  .contacts {
    padding: 16px;
  }

  .contacts li {
    background: #fff;
    border-radius: 6px;
    margin-top: 16px;
    box-sizing: border-box;
    padding: 10px;
  }

  .contacts li:first-child {
    margin-top: 0;
  }

  .contacts li h2 {
    font-size: 16px;
    line-height: 32px;
    margin: 0;
    padding: 0 12px;
  }

  .contacts li .time {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    font-size: 14px;
    color: #777;
  }

  .contacts li .contact {
    padding: 12px;
    margin: 8px;
    border-radius: 5px;
    background: #f7f7f7;
  }

  .contacts li .contactinfo {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    line-height: 23px;
    font-size: 13px;
  }

  .contacts li .contactinfo .title {
    display: inline-block;
    width: 30%;
  }

  .contacts li .contactinfo .content {
    display: inline-block;
    width: 70%;
    text-align: right;
  }

  .contacts li h2 .status {
    font-size: 14px;
    font-weight: normal;
    color: #d6a741;
    float: right;
  }
}

@media (min-width: 679px) {
  .agroup-page {
    display: none;
  }

  .pc-my-page .main {
    padding: 36px 30px;
  }
}
</style>